import React from "react";
import { Routes, Route } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./Home";
import Contact from "./components/Sections/Contact";
import Footer from "./components/Footer/Footer";
import CookiePolicy from "./legal/cookies-policy/CookiePolicy";
import PrivacyPolicy from "./legal/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "./legal/terms-and-conditions/TermsAndConditions";
import PageNotFound from "./PageNotFound";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="legal/cookies-policy" element={<CookiePolicy />} />
          <Route path="legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="legal/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="*" component={PageNotFound} element={<PageNotFound />} />
        </Routes>
      </div>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
