import React from "react";

const CookiePolicy = () => {
  return (
    <section className="cookie-policy w-full py-3 px-5 bg-pcdwhite text-pcdblack">
      <div className="relative text-left container mx-auto py-12 pt-48">
        <div className="aboutHeadline py-5">
          <h2 className="text-2xl md:text-6xl font-extrabold tracking-tighter border-b-2 border-gray-300 pb-3">
            Cookies Policy
          </h2>
        </div>
        <div className="container py-3">
          <p className="font-semibold">
            Last Updated:{" "}
            <date className="tag">
              August 5<sup>th</sup>, 2022
            </date>
          </p>
          <h4 className="text-lg font-extrabold underline py-3 ">Notice</h4>
          <p className="">
            This website or its third-party applications process personal data
            and use cookies which are necessary for the appropriate functioning
            of this website and otherwise are to the purpose documented in the
            Cookie Policy.
          </p>
          <p className="">
            {" "}
            You accept the use of cookies and other identifiers by Accepting,
            Closing, Dismissing this notice, or by clicking a link or button, or
            by continuing to browse otherwise. You refuse by Rejecting, exiting
            the website, visiting another site, closing the tab, or by closing
            the browser.
          </p>
        </div>
        <div className="container py-3">
          <h4 className="text-lg font-extrabold underline pb-3 ">
            Service Notice
          </h4>
          <p className="">
            Please read these Cookies Policy carefully before using Our Service.
            This Cookies Policy explains what Cookies are and how We use them.
            You should read this policy so You can understand what type of
            cookies We use, or the information We collect using Cookies and how
            that information is used.
          </p>
          <p className="">
            Cookies do not typically contain any information that personally
            identifies a user, but personal information that we store about You
            may be linked to the information stored in and obtained from
            Cookies. For further information on how We use, store and keep your
            personal data secure, see our Privacy Policy.
          </p>
          <p className="">
            We do not store sensitive personal information, such as mailing
            addresses, account passwords, etc. in the Cookies We use.
          </p>
        </div>
        <div className="container py-3">
          <h4 className="text-lg font-extrabold underline pb-3 ">
            Interpretation and Definitions
          </h4>
          <h5 className="text-md font-bold underline">Interpretation</h5>
          <p className="">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions.
          </p>
          <p className=" pb-3">
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </p>
          <h5 className="text-md font-bold underline">Definitions</h5>
          <p className="">For the purposes of These Terms and Conditions:</p>
          <ul className=" pl-5">
            <li>
              <strong>Company</strong> (referred to as either "the Company",
              "We", "Us" or "Our" in this Agreement) refers to ParadeCode
              Digital, DP Digital Block Inc, including DP Digital Block Inc.
              Child Companies.
            </li>
            <li>
              <strong>You</strong> means the individual accessing or using the
              Website, or a company, or any legal entity on behalf of which such
              individual is accessing or using the Website, as applicable.
            </li>
            <li>
              <strong>Cookies</strong> means small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing details of your browsing history on that website among
              its many uses.
            </li>
            <li>
              <strong>Website</strong> refers to ParadeCode, accessible from{" "}
              <a
                className="underline font-semibold hover:text-pcdblue"
                href="https:www.paradecode.com"
              >
                https://www.paradecode.com.
              </a>
            </li>
          </ul>
        </div>
        <div className="container py-3">
          <h4 className="text-lg font-extrabold underline pb-3">
            The use of Cookies
          </h4>
          <h5 className="text-md font-bold underline">
            Type of Cookies We Use
          </h5>
          <p className="">
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close your
            web browser.
          </p>
          <p className="">
            We use both session and persistent Cookies for the purposes set out
            below:
          </p>
          <ul className="pl-5">
            <li>
              <strong>Necessary/Essential Cookies</strong>
            </li>
            <li>
              <strong>Type:</strong> Persistent Cookies
            </li>
            <li>
              <strong>Adminstered by:</strong> Us
            </li>
            <li>
              <strong>Purpose</strong> These Cookies allow us to remember
              choices You make when You use the Website, such as remembering
              your login details or language preference. The purpose of these
              Cookies is to provide You with a more personal experience and to
              avoid You having to re-enter your preferences every time You use
              the Website.
            </li>
          </ul>
        </div>

        <div className="container py-3">
          <h4 className="text-lg font-extrabold underline pb-3">
            Your Choices Regarding Cookies
          </h4>
          <p className="">
            If You prefer to avoid the use of Cookies on the Website, first You
            must disable the use of Cookies in your browser and then delete the
            Cookies saved in your browser associated with this website. You may
            use this option for preventing the use of Cookies at any time.
          </p>
          <p className="">
            If You do not accept Our Cookies, You may experience some
            inconvenience in your use of the Website and some features may not
            function properly.
          </p>
          <p className="">
            If You'd like to delete Cookies or instruct your web browser to
            delete or refuse Cookies, please visit the help pages of your web
            browser.
          </p>
          <ul className=" pl-5">
            <li>
              <strong>Google Chrome</strong> For the Chrome web browser, please
              visit this page from Google:
              <a
                className="underline font-semibold hover:text-pcdblue"
                href="https://support.google.com/accounts/answer/32050 "
              >
                Chrome - Clear Cache and Cookies
              </a>
            </li>
            <li>
              <strong>Internet Explorer</strong> For the Internet Explorer web
              browser, please visit this page from Microsoft:{" "}
              <a
                className="underline font-semibold hover:text-pcdblue"
                href="https://support.microsoft.com/en-ca/help/4027947/microsoft-edge-delete-cookies"
              >
                Edge - Delete Cookies in Microsoft Edge
              </a>
            </li>
            <li>
              <strong>Firefox</strong> For the Firefox web browser, please visit
              this page from Mozilla:
              <a
                className="underline font-semibold hover:text-pcdblue"
                href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
              >
                Firefox - Clear Cookies and Site Data in Firefox
              </a>
            </li>
            <li>
              <strong>Safari</strong> For the Safari web browser, please visit
              this page from Apple:
              <a
                className="underline font-semibold hover:text-pcdblue"
                href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              >
                Safari - Manage Cookies and Website Dara in Safari
              </a>
            </li>
          </ul>
          <p className="">
            For any other web browser, please visit your web browser's official
            web pages.
          </p>
          <p className="">
            If above links are not available, utilize Search Engines of your
            choosing to find the appropiate guide to do so.
          </p>
        </div>

        <div className="container py-3">
          <h4 className="text-lg font-extrabold underline pb-3">
            More Information about Cookies
          </h4>
          <p className="">
            Utilize Search Engines to learn more about cookies.
          </p>
        </div>
        <div className="container py-3">
          <h4 className="text-lg font-extrabold underline pb-3">Contact Us</h4>
          <p className="">
            If you have any questions about these Terms and Conditions, You can
            contact us:
          </p>
          <ul className=" pt-2 pl-5 ">
            <li>
              <strong>
                By email:{" "}
                <a
                  className="underline font-semibold hover:text-pcdblue"
                  href="mailto:hello@paradecode.com"
                >
                  hello@paradecode.com
                </a>
              </strong>
            </li>
            <li>
              <strong>By submitting a Contact Form</strong>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CookiePolicy;
