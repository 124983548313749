import React from "react";
import video from "./../../assets/img/hero/ParadeCodeDigitalHeroVideo.mp4";

const HeroVideo = () => {
  return (
    <section className="hero-video-wrapper mx-auto px-5 bg-pcdwhite">
      <div className="hero-video-section 2xl:mx-16 rounded-t-3xl">
        <div className="container mx-auto z-40 hero-video ">
          <video src={video} autoPlay loop muted typeof="video/mp4" />
        </div>
      </div>
    </section>
  );
};

export default HeroVideo;
