import React from "react";
import { Routes, Route } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-pcdoffblack pt-8" aria-labelledby="footer-heading ">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-5 mx-auto container lg:py-16 md:px-12 lg:px-1">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <a href="/" className="flex items-center -mt-2">
              <span className="self-center text-2xl md:text-4xl font-extrabold whitespace-nowrap text-pcdwhite tracking tracking-tighter">
                &#9679;<span className="text-pcdblue pr-2">&#9679;</span>
                ParadeCode Digital
              </span>
            </a>
            <h3 className="text-xs font-semibold tracking-wider text-pcdblue uppercase">
              Company
            </h3>
            <p className="mt-4 text-sm text-gray-200 lg:ml-auto">
              ParadeCode Digital is a Alberta Web Development Marketing Agency
              that specializes in Front End Development. Services include Brand
              Strategy, Website Development, Search Engine Optimization (SEO),
              and Social Media Marketing (SMM: Instagram).
            </p>
          </div>
          <div className="grid grid-cols-2 gap-8 mt-12 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-xs font-semibold tracking-wider text-pcdblue uppercase">
                  Menu
                </h3>
                <ul className="mt-4 space-y-4">
                  <HashLink smooth to="/#about">
                    <li className="text-sm font-normal text-gray-200 hover:text-pcdblue mb-4">
                      {/* <a href="#about" className="text-sm font-normal text-gray-200 hover:text-pcdblue" >{" "}About{" "}</a> */}
                      About
                    </li>
                  </HashLink>

                  <HashLink smooth to="/#services">
                    <li className="text-sm font-normal text-gray-200 hover:text-pcdblue mb-4">
                      {/* <a href="#services" className="text-sm font-normal text-gray-200 hover:text-pcdblue">{" "}Services{" "}</a> */}
                      Services
                    </li>
                  </HashLink>

                  <HashLink smooth to="/#showcase">
                    <li className="text-sm font-normal text-gray-200 hover:text-pcdblue">
                      {/* <a href="#showcase" className="text-sm font-normal text-gray-200 hover:text-pcdblue" >{" "}Showcase{" "}</a> */}
                      Showcase
                    </li>
                  </HashLink>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-xs font-semibold tracking-wider text-pcdblue uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  <li className="text-sm font-normal text-gray-200 hover:text-pcdblue">
                    <Link to="./../../legal/privacy-policy/">
                      {/* <a
                        href="/legal/privacy-policy"
                        className="text-sm font-normal text-gray-200 hover:text-pcdblue"
                      >
                        {" "}
                        Privacy Policy{" "}
                      </a> */}
                      Privacy Policy
                    </Link>
                  </li>

                  <li className="text-sm font-normal text-gray-200 hover:text-pcdblue">
                    <Link to="./../legal/cookies-policy/">
                      {/* <a
                        href="/legal/cookies-policy"
                        className="text-sm font-normal text-gray-200 hover:text-pcdblue"
                      >
                        {" "}
                        Cookies Policy{" "}
                      </a> */}
                      Cookies Policy
                    </Link>
                  </li>

                  <li className="text-sm font-normal text-gray-200 hover:text-pcdblue">
                    <Link to="/../../legal/terms-and-conditions/">
                      {/* <a
                        href="/legal/terms-and-conditions"
                        className="text-sm font-normal text-gray-200 hover:text-pcdblue"
                      >
                        {" "}
                        Terms and Conditions{" "}
                      </a> */}
                      Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:justify-end xl:grid md:grid-cols-1 hidden">
              <div className="w-full mt-12 md:mt-0">
                <div className="mt-8 lg:justify-end xl:mt-0">
                  <h3 className="text-xs font-semibold tracking-wider text-pcdblue uppercase">
                    Acknowledgements
                  </h3>
                  <p className="mt-4 text-sm text-gray-200 lg:ml-auto">
                    We acknowledge that what we call Alberta is the traditional
                    and ancestral territory of many peoples, presently subject
                    to Treaties 6, 7, and 8. Namely: the Blackfoot Confederacy –
                    Kainai, Piikani, and Siksika – the Cree, Dene, Saulteaux,
                    Nakota Sioux, Stoney Nakoda, and the Tsuu T’ina Nation and
                    the Métis People of Alberta. This includes the Métis
                    Settlements and the Six Regions of the Métis Nation of
                    Alberta within the historical Northwest Metis Homeland. We
                    acknowledge the many First Nations, Métis and Inuit who have
                    lived in and cared for these lands for generations. We are
                    grateful for the traditional Knowledge Keepers and Elders
                    who are still with us today and those who have gone before
                    us. We make this acknowledgement as an act of reconciliation
                    and gratitude to those whose territory we reside on or are
                    visiting.
                  </p>
                  <div className="inline-flex items-center gap-2 list-none lg:ml-auto"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:justify-end md:grid md:grid-cols-1 block xl:hidden">
            <div className="w-full my-8 md:mt-0">
              <div className="mt-8 lg:justify-end xl:mt-0">
                <h3 className="text-xs font-semibold tracking-wider text-pcdblue uppercase">
                  Acknowledgements
                </h3>
                <p className="mt-4 text-sm text-gray-200 lg:ml-auto">
                  We acknowledge that what we call Alberta is the traditional
                  and ancestral territory of many peoples, presently subject to
                  Treaties 6, 7, and 8. Namely: the Blackfoot Confederacy –
                  Kainai, Piikani, and Siksika – the Cree, Dene, Saulteaux,
                  Nakota Sioux, Stoney Nakoda, and the Tsuu T’ina Nation and the
                  Métis People of Alberta. This includes the Métis Settlements
                  and the Six Regions of the Métis Nation of Alberta within the
                  historical Northwest Metis Homeland. We acknowledge the many
                  First Nations, Métis and Inuit who have lived in and cared for
                  these lands for generations. We are grateful for the
                  traditional Knowledge Keepers and Elders who are still with us
                  today and those who have gone before us. We make this
                  acknowledgement as an act of reconciliation and gratitude to
                  those whose territory we reside on or are visiting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 py-12 mx-auto  bg-pcdblue container sm:px-6 md:flex md:items-center md:justify-between lg:px-20">
        <div className="flex justify-left mb-8 space-x-6 md:order-last md:mb-0">
          <a
            href="https://www.instagram.com/paradecodedigital/"
            className="text-pcdwhite hover:text-blue-900"
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only">Instagram</span>
            <svg
              className="h-6 w-6 text-white hover:text-blue-700"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {" "}
              <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />{" "}
              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
              <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/ParadeCode-Digital-107272448714884"
            className="text-pcdwhite hover:text-blue-900"
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only">Facebook</span>
            <svg
              className="h-6 w-6 text-white hover:text-blue-700"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {" "}
              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/in/paradecodedigital/"
            className="mr-5 text-pcdwhite hover:text-blue-900"
            rel="noopener noreferrer"
            target="_blank"
          >
            <svg
              className="h-6 w-6 text-white hover:text-blue-700"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {" "}
              <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />{" "}
              <rect x="2" y="9" width="4" height="12" />{" "}
              <circle cx="4" cy="4" r="2" />
            </svg>
            <span className="sr-only">Linkedin page</span>
          </a>
        </div>

        <div className="mt-8 md:mt-0 md:order-1">
          <span className="mt-2 text-sm font-medium text-pcdwhite">
            Copyright © 2022 | DP Digital Block Inc.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
