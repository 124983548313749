import React from "react";

const CTA = () => {
  return (
    <section className="w-full px-5 pb-10 -mt-10">
      <div className="container mx-auto cta-wrapper flex flex-row items-center">
        <div className="appointment">
          <button className="ctaone mr-5 px-6 py-3 text-sm md:text-lg font-semibold text-center text-pcdwhite  bg-pcdblue rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg hover:shadow-2xl hover:-translate-y-1">
            <a
              href="https://calendly.com/paradecodedigital"
              target="_blank"
              rel="noreferrer"
            >
              Book a Date
            </a>
          </button>
        </div>
        <div className="email">
          <button className="ctatwo px-6 py-3 text-sm md:text-lg font-semibold text-center text-pcdwhite  bg-pcdblue rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg hover:shadow-2xl hover:-translate-y-1">
            {/* <a href="mailto:hello@paradecode.com">Contact Us</a> */}
            <a href="#contact">Contact Us</a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default CTA;
