import React from "react";

const Services = (props) => {
  const ServiceLinks = [
    {
      number: "01.",
      headline: "Web Development",
      tagline:
        "Your clients will judge your website within the first few seconds for their first impression.",
      marquee:
        "Web Development - Web Development - Web Development - Web Development - Web Development - Web Development - Web Development",
      listing: (
        <ul className="flex flex-col">
          <li>Custom Code</li>
          <li>Wordpress/Shopify</li>
          <li>Responsive Web</li>
          <li>Semantic Build</li>
        </ul>
      ),
      image: require("./../../assets/img/services/web-dev/web-dev-action.webp"),
      alt: "Web Development ParadeCode Digital",
    },
    {
      number: "02.",
      headline: "Search Engine Optimization",
      tagline:
        "Improving the Quality and the Quantity of Relevant Traffic to your Website.",
      marquee:
        "Search Engine Optimization - Search Engine Optimization - Search Engine Optimization - Search Engine Optimization - Search Engine Optimization - Search Engine Optimization - Search Engine Optimization",
      listing: (
        <ul className="flex flex-col">
          <li>Technical SEO</li>
          <li>On-Site SEO</li>
          <li>Google Optimization</li>
          <li>Sitemap/Robots.txt</li>
        </ul>
      ),
      image: require("./../../assets/img/services/seo/seo-action.webp"),
      alt: "Search Engine Optimization ParadeCode Digital",
    },
    // {
    //   number: "03.",
    //   headline: "Brand Strategy",
    //   tagline:
    //     "We identify your brand identity and your core focus, to help your brand become sucessful.",
    //   marquee:
    //     "Brand Strategy - Brand Strategy - Brand Strategy - Brand Strategy - Brand Strategy - Brand Strategy - Brand Strategy",
    //   listing: (
    //     <ul className="flex flex-col">
    //       <li>Scope</li>
    //       <li>Logo</li>
    //       <li>Colours</li>
    //       <li>Guidelines</li>
    //     </ul>
    //   ),
    //   image: require("./../../assets/img/services/brand-strategy/brand-strategy-action.webp"),
    //   alt: "Brand Strategy ParadeCode Digital",
    // },
    // {
    //   number: "04.",
    //   headline: "Social Media Marketing",
    //   tagline:
    //     "Utlizing Social Media to create online presence and obtain greater reach.",
    //   marquee:
    //     "Social Media Marketing - Social Media Marketing - Social Media Marketing - Social Media Marketing - Social Media Marketing - Social Media Marketing - Social Media Marketing",
    //   listing: (
    //     <ul className="flex flex-col">
    //       <li>Instagram</li>
    //       <li>Posts</li>
    //       <li>Hashtags</li>
    //       <li>Reach</li>
    //     </ul>
    //   ),
    //   image: require("./../../assets/img/services/smm/smm-action.webp"),
    //   alt: "Social Media Marketing ParadeCode Digital",
    // },
    {
      number: "03.",
      headline: "3D Architectural Renderings",
      tagline: "Bringing your pen and paper plans to life.",
      marquee:
        "3D Architectural Renderings - 3D Architectural Renderings - 3D Architectural Renderings - 3D Architectural Renderings - 3D Architectural Renderings - 3D Architectural Renderings - 3D Architectural Renderings",
      listing: (
        <ul className="flex flex-col">
          <li>3D Exterior Renderings</li>
          <li>3D Interior Renderings</li>
          <li>3D Floor Layouts</li>
          <li>Animation</li>
        </ul>
      ),
      image: require("./../../assets/img/services/3d-architectural-renderings/3D-architectural-renderings-paradecode-digital.webp"),
      alt: "Social Media Marketing ParadeCode Digital",
    },
  ];
  return (
    <section
      className="services w-full px-5 py-5 bg-pcdwhite text-pcdblack"
      id="services"
    >
      <div className="container mx-auto py-16">
        <h2 className="text-5xl md:text-8xl font-bold tracking-tighter ">
          Services
        </h2>
        <p className="text-gray-500 text-lg mt-3">What we do.</p>
      </div>

      {/* Animate Section */}
      <div className="container mx-auto hidden lg:block">
        {ServiceLinks.map((links, link) => (
          <div className="relative group" key={link}>
            <div
              className="service-wrapper relative border-t-2 grid grid-cols-1 md:grid-cols-5 py-8 group-hover peer"
              key={link}
            >
              <div className="w-12 text-lg md:text-3xl font-semibold z-40">
                {links.number}
              </div>
              <div className="text-3xl md:text-5xl font-extrabold col-span-2 z-40 mt-4 md:mt-0 peer">
                {links.headline}
              </div>
              <div className="flex flex-col col-span-2">
                <div className="text-lg md:text-2xl font-semibold z-40">
                  {links.tagline}
                </div>
                <div className="text-md font-semibold pt-5 z-40">
                  {links.listing}
                </div>
              </div>
              <marquee
                behavior="scroll"
                direction="left"
                className="absolute z-30 font-extrabold text-9xl top-16 pb-4 -mx-96 text-gray-100"
              >
                {links.marquee}
              </marquee>
            </div>
            <img
              src={links.image}
              alt={links.alt}
              className="invisible w-72 left-10 -top-4 h-auto absolute z-20 peer-hover:invisible lg:peer-hover:visible"
            />
          </div>
        ))}
      </div>
      {/* Static Section */}
      <div className="grid grid-cols-1 gap-12 lg:gap-24 lg:grid-cols-2 container mx-auto lg:hidden">
        {ServiceLinks.map((links, link) => (
          <div className="sm:flex lg:items-start group" key={link}>
            <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4">
              <img
                className="w-full sm:h-96 sm:w-96 md:h-60 md:w-60 xl:w-96 xl:h-96 object-cover aspect-square"
                src={links.image}
                alt={links.alt}
              />
            </div>
            <div>
              <span className="text-sm text-gray-500">{links.number}</span>
              <h3 className="mt-3 text-lg font-medium leading-6">
                {links.headline}
              </h3>
              <p className="mt-2 text leading-normal text-gray-500 max-w-sm">
                {links.tagline}
              </p>
              <div className="mt-2 text text-pcdblack">{links.listing}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
