import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax, Mousewheel, Autoplay, A11y } from "swiper";

import "swiper/css/bundle";

const Portfolio = () => {
  const PortfolioLink = [
    {
      name: "Bar & Grill",
      link: "https://smithers.paradecode.com/",
      image: require("./../../assets/img/portfolio/barandgrill.webp"),
      alt: "ParadeCode Digital Portfolio Resturant Bar and Grill Mockup Website",
      tag: "A recreation of one of the most popular types of businesses on the web. Food! Equiped with a Tabbed Food Menu.",
      languages: "Custom Code - Tailwind CSS",
    },
    {
      name: "Banking",
      link: "https://banking.paradecode.com/",
      image: require("./../../assets/img/portfolio/banking.webp"),
      alt: "ParadeCode Digital Portfolio Banking Mockup Website",
      tag: "A clean landing page interpretation of a Local Bank offering amazing benefits with a clear call-to-action to draw the attention of the visitor. ",
      languages: "Custom Code - Tailwind CSS",
    },
    {
      name: "Law Firm",
      link: "https://lawfirm.paradecode.com/",
      image: require("./../../assets/img/portfolio/lawfirm.webp"),
      alt: "ParadeCode Digital Portfolio Law Firm Mockup Website",
      tag: "A Law Agency Website with bold colours, and clear recognizable call-to-action, and listing of attorneys. ",
      languages: "Custom Code - Tailwind CSS",
    },
  ];

  return (
    <section
      className="portfolio w-full py-3 px-5 bg-pcdwhite text-pcdblack"
      id="showcase"
    >
      <div className=" container mx-auto relative text-left tracking-tighter py-12 ">
        <div className="aboutHeadline py-5">
          <h2 className="text-5xl md:text-8xl font-bold tracking-tighter">
            Showcase
          </h2>
          <p className="text-gray-500 text-md mt-3">What we done.</p>
        </div>
        <div className="div portfolio-wrapper flex flex-col">
          <div className="portfolio-header">
            <p className="items-center text-md mb-3">
              Check out any of our showcase websites to get an idea what we can
              build for you!
            </p>
          </div>
          <div className="portfolio-list max-w-full  ml-auto py-3">
            <Swiper
              modules={[Mousewheel, Parallax, A11y, Autoplay]}
              grabCursor={true}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
            >
              {PortfolioLink.map((links, link) => (
                <SwiperSlide key={link}>
                  <div className="service-item">
                    <img
                      src={links.image}
                      alt={links.alt}
                      className="h-96 w-full mx-auto py-8 object-cover "
                    />
                    <div className="service-item-text">
                      <p className="text-sm text-pcdoffblack  border-t border-pcdoffblack pt-2">
                        {links.tag}
                      </p>
                      <div className="service-listing text-md flex flex-row my-2">
                        {links.languages}
                      </div>
                      <h3 className="text-xl font-semibold text-pcdblack underline">
                        <marquee behavior="scroll" direction="left">
                          {links.name} - {links.name} - {links.name}
                        </marquee>
                      </h3>
                      <a
                        href={links.link}
                        alt={links.alt}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="py-3"
                      >
                        <button className="tracking-tighter text-lg font-semibold text-pcdwhite bg-pcdblue px-3 py-1.5 rounded-2xl transition duration-500 ease-in-out transform  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg hover:shadow-2xl hover:-translate-y-1 text-center my-3">
                          Visit {links.name}
                        </button>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
