import React from "react";
import Services from "./components/Sections/Services";

const PageNotFound = () => {
  return (
    <section className="pagenotfound px-5">
      <div className="container mx-auto bg-pcdwhite text-pcdblack py-52">
        <h2 className="text-5xl md:text-8xl font-bold tracking-tighter ">
          Page Not Found
        </h2>
        <p className="text-gray-500 text-lg mt-3">Where you going?</p>
        <p className="text-xl font-medium pt-8">
          Use the Navigation Menu, or the Links in the Footer below
        </p>
        <p className="text-xl font-medium">
          Feel Free to continue scrolling to see the services we provide!
        </p>
      </div>
      <Services />
    </section>
  );
};

export default PageNotFound;
