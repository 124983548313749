import React from "react";

import { UserGroupIcon } from "@heroicons/react/solid";
import { ScaleIcon } from "@heroicons/react/solid";
import { ShieldCheckIcon } from "@heroicons/react/solid";

// const About = () => {
//   const AboutLinks = [
//     {
//       number: "01.",
//       headline: "Hiring Us",
//       tag: "You save with in-house expenses, not worrying about having to supervise the work, not have to worry about training, and not have to worry about extra costs. We already have been trained. We just do the work. Like we are part of the team already.",
//       image: require("./../../assets/img/services/hireparadecodedigital.gif"),
//       alt: "ParadeCode Digital About Company",
//       svg: (
//         <UserGroupIcon className="h-14 w-14 p-3 bg-blue-200 rounded-full text-pcdblue" />
//       ),
//     },
//     {
//       number: "02.",
//       headline: "No Juggle Acts",
//       tag: "Instead of having to juggle work tasks, we do the work for you. We only update you for the good times. This will allow you to focus your time and energy on more pressing issues.",
//       image: require("./../../assets/img/services/balancing.gif"),
//       alt: "ParadeCode Digital About Company",
//       svg: (
//         <ScaleIcon className="h-14 w-14 p-3 bg-blue-200 rounded-full text-pcdblue" />
//       ),
//     },
//     {
//       number: "03.",
//       headline: "Peace of Mind",
//       tag: "Smile, you are getting a company with experience and knows its way around the digital jungle that is the web. With years of experience, ParadeCode Digital will ensure that you are happy with the result, both visually and metrically.",
//       image: require("./../../assets/img/services/pieceofmind.gif"),
//       alt: "ParadeCode Digital About Company",
//       svg: (
//         <ShieldCheckIcon className="h-14 w-14 p-3 bg-blue-200 rounded-full text-pcdblue" />
//       ),
//     },
//   ];
const About = () => {
  const AboutLinks = [
    {
      number: "01.",
      headline: "Establish Clear Goals and Expectations",
      tag: "Start by aligning your goals and clearly defining expectations. This step involves open and honest communication to ensure everyone involved understands the objectives, timelines, and desired outcomes of the partnership. By setting clear expectations from the outset, you create a solid foundation for collaboration and minimize misunderstandings along the way.",
      image: require("./../../assets/img/services/hireparadecodedigital.gif"),
      alt: "ParadeCode Digital About Company",
      svg: (
        <UserGroupIcon className="h-14 w-14 p-3 bg-blue-200 rounded-full text-pcdblue" />
      ),
    },
    {
      number: "02.",
      headline: "Foster Open and Transparent Communication",
      tag: "Communication is the lifeline of any successful partnership. Encourage open dialogue, active listening, and regular updates between all parties involved. Maintain transparency in sharing progress, challenges, and opportunities. Effective communication builds trust, strengthens relationships, and enables timely problem-solving, ultimately driving the partnership towards success.",
      image: require("./../../assets/img/services/balancing.gif"),
      alt: "ParadeCode Digital About Company",
      svg: (
        <ScaleIcon className="h-14 w-14 p-3 bg-blue-200 rounded-full text-pcdblue" />
      ),
    },
    {
      number: "03.",
      headline: "Nurture Mutual Trust and Collaboration",
      tag: "Trust forms the cornerstone of a successful partnership. Cultivate an environment of mutual respect, trust, and collaboration. Embrace a cooperative mindset, valuing each other's expertise and contributions. Encourage teamwork, idea-sharing, and joint decision-making. By fostering trust and collaboration, you unlock the full potential of the partnership, allowing for innovative solutions and shared successes.",
      image: require("./../../assets/img/services/pieceofmind.gif"),
      alt: "ParadeCode Digital About Company",
      svg: (
        <ShieldCheckIcon className="h-14 w-14 p-3 bg-blue-200 rounded-full text-pcdblue" />
      ),
    },
  ];

  return (
    // <section className="about py-5 px-5  text-pcdblack" id="about">
    //   <div className="about container mx-auto py-16">
    //     <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-x-16 lg:items-start">
    //       <div className="max-w-xl mx-auto lg:text-left lg:mx-0">
    //         <h2 className="text-5xl md:text-8xl font-bold tracking-tighter ">
    //           About Us
    //         </h2>
    //         <p className="text-gray-500 text-lg mt-3">Who are we.</p>
    //         <p className="mt-8 text-md tracking-tighter">
    //           ParadeCode Digital is a Edmonton Web Development Marketing Agency
    //           that specializes in Front End Development. Working with the right
    //           company for your business needs is more than a practical choice;
    //           it's a deeply personal one. It's about finding a partner who not
    //           only understands your vision but also shares your passion, pushing
    //           boundaries and unlocking the full potential of your business. This
    //           collaboration becomes an emotional necessity, fueling inspiration,
    //           growth, and a sense of purpose. Choose a company that connects
    //           with your heart as well as your goals, because together, you can
    //           create something truly extraordinary.
    //         </p>
    //         {/* <p className="mt-8 text-md tracking-tighter">
    //           ParadeCode Digital is a Edmonton Web Development Marketing Agency
    //           that specializes in Front End Development. We can create anything
    //           from Landing Pages, Traditional Websites, Blogs, Magazine, and
    //           eCommerce websites. We also provide services for Search Engine
    //           Optimization with SEO Tools and Utilities, Brand Strategy and
    //           Development, and Social Media Marketing. We also help Home Builders with renders
    //         </p> */}
    //         <div className="about social flex flex-col text-xl space-y-2 pt-4 md:pt-24">
    //           <a
    //             href="https://www.instagram.com/paradecodedigital/"
    //             className="hover:text-pcdblue hover:underline w-fit"
    //             rel="noopener noreferrer"
    //             target="_blank"
    //           >
    //             Instagram
    //           </a>
    //           <a
    //             href="https://www.facebook.com/ParadeCode-Digital-107272448714884"
    //             className="hover:text-pcdblue hover:underline w-fit"
    //             rel="noopener noreferrer"
    //             target="_blank"
    //           >
    //             Facebook
    //           </a>
    //           <a
    //             href="https://www.linkedin.com/in/paradecodedigital/"
    //             className="hover:text-pcdblue hover:underline w-fit"
    //             rel="noopener noreferrer"
    //             target="_blank"
    //           >
    //             Linkedin
    //           </a>
    //         </div>
    //       </div>
    //       <div className="grid grid-cols-1 gap-4 tracking-tighter max-w-xl mx-auto">
    //         {AboutLinks.map((links, link) => (
    //           <div className="block mt-3 border-b" key={link}>
    //             <span className="text-md font-extrabold tracking-tighter">
    //               {links.number}
    //             </span>
    //             <p className="text-base tracking-tighter my-5 pb-8">
    //               {links.tag}
    //             </p>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <section className="about py-5 px-5  text-pcdblack" id="about">
      <div className="about container mx-auto py-16">
        <div className="">
          <div className="max-w-6xl mx-auto lg:text-left lg:mx-0">
            <h2 className="text-5xl md:text-8xl font-bold tracking-tighter ">
              About Us
            </h2>
            <p className="text-gray-500 text-lg mt-3">Who are we.</p>
            <p className="mt-10 text-md lg:font-semibold lg:text-2xl lg:ml-96 tracking-tighter">
              ParadeCode Digital is a Edmonton Web Development Marketing Agency
              that specializes in Front End Development.<br></br>
              <br></br>Working with the right company for your business needs is
              more than a practical choice; it's a deeply personal one. It's
              about finding a partner who not only understands your vision but
              also shares your passion, pushing boundaries and unlocking the
              full potential of your business. This collaboration becomes an
              emotional necessity, fueling inspiration, growth, and a sense of
              purpose. Choose a company that connects with your heart as well as
              your goals, because together, you can create something truly
              extraordinary.
            </p>
            {/* <p className="mt-8 text-md tracking-tighter">
              ParadeCode Digital is a Edmonton Web Development Marketing Agency
              that specializes in Front End Development. We can create anything
              from Landing Pages, Traditional Websites, Blogs, Magazine, and
              eCommerce websites. We also provide services for Search Engine
              Optimization with SEO Tools and Utilities, Brand Strategy and
              Development, and Social Media Marketing. We also help Home Builders with renders
            </p> */}
            {/* <div className="about social flex flex-col text-xl space-y-2 pt-4 md:pt-24">
              <a
                href="https://www.instagram.com/paradecodedigital/"
                className="hover:text-pcdblue hover:underline w-fit"
                rel="noopener noreferrer"
                target="_blank"
              >
                Instagram
              </a>
              <a
                href="https://www.facebook.com/ParadeCode-Digital-107272448714884"
                className="hover:text-pcdblue hover:underline w-fit"
                rel="noopener noreferrer"
                target="_blank"
              >
                Facebook
              </a>
              <a
                href="https://www.linkedin.com/in/paradecodedigital/"
                className="hover:text-pcdblue hover:underline w-fit"
                rel="noopener noreferrer"
                target="_blank"
              >
                Linkedin
              </a>
            </div> */}
          </div>
          <div className="grid grid-cols-1 gap-4 tracking-tighter max-w-5xl mx-auto mt-24 ">
            {AboutLinks.map((links, link) => (
              <div className="block mt-3 border-b" key={link}>
                <span className="text-md font-bold tracking-tighter">
                  {links.number}
                </span>
                <h5 className="text-lg font-extrabold tracking-tighter mt-3">
                  {links.headline}
                </h5>
                <p className="text-base tracking-tighter my-5 pb-8">
                  {links.tag}
                </p>
              </div>
            ))}
          </div>
          {/* <div className="grid grid-cols-1 gap-4 tracking-tighter max-w-xl mx-auto mt-12">
            {AboutLinks.map((links, link) => (
              <div className="block mt-3 border-b" key={link}>
                <span className="text-md font-extrabold tracking-tighter">
                  {links.number}
                </span>
                <p className="text-base tracking-tighter my-5 pb-8">
                  {links.tag}
                </p>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default About;
