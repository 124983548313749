import React from "react";

const Contact = () => {
  return (
    <section
      className="w-full px-5 pt-8 bg-pcdoffblack text-pcdwhite -z-10"
      id="contact"
    >
      <div className="strategy container mx-auto relative text-left tracking-tighter py-12 ">
        <div className="aboutHeadline py-5">
          <h2 className="text-5xl md:text-8xl font-bold tracking-tighter">
            Contact
          </h2>
          <p className="text-gray-200 text-lg mt-3">Where we are.</p>
        </div>
        <div className="flex flex-col md:flex-row mt-10">
          <div className="contact-form w-full md:max-w-2xl">
            <h3 className="text-xl font-semibold  text-pcdblue">
              Contact Form
            </h3>
            <form
              action="https://formsubmit.co/24581a4b5e1fc29e12593ded713c2468"
              method="POST"
              className="space-y-8 mt-5"
            >
              <input
                type="text"
                name="_honey"
                id=""
                style={{ display: "none" }}
              ></input>
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-mediumtext-gray-300"
                >
                  Full Name
                </label>
                <input
                  type="name"
                  id="name"
                  className=" bg-pcdoffblack border border-gray-300 text-sm text-pcdwhite rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                  placeholder="Enter Full name Here"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-mediumtext-gray-300"
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  className=" bg-pcdoffblack border border-gray-300 text-sm text-pcdwhite rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                  placeholder="Email Address Here"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="subject"
                  className="block mb-2 text-sm font-mediumtext-gray-300"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  className=" bg-pcdoffblack border border-gray-300 text-sm text-pcdwhite rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                  placeholder="Let us know how we can help you"
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-mediumtext-gray-400"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows="6"
                  className="bg-pcdoffblack border border-gray-300 text-sm text-pcdwhite rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                  placeholder="Leave a comment..."
                ></textarea>
              </div>
              <button
                type="submit"
                className="px-4 py-3 text-md font-semibold text-center text-pcdwhite transition duration-500 ease-in-out transform bg-pcdblue rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg hover:shadow-2xl hover:-translate-y-1"
              >
                Send message
              </button>
            </form>
          </div>
          <div className="gap w-1/4 mt-20 md:mt-0"></div>
          <div className="contact-details w-full md:w-1/2">
            <h3 className="text-xl font-semibold  text-pcdblue">
              Contact Details
            </h3>
            <div className="appointment mt-5">
              <button className="px-3 py-2 text-sm font-medium text-center text-pcdwhite transition duration-500 ease-in-out transform bg-pcdblue rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg hover:shadow-2xl hover:-translate-y-1">
                <a
                  href="https://calendly.com/paradecodedigital"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Appointment
                </a>
              </button>
            </div>
            <div className="email mt-3">
              <button className="px-3 py-2 text-sm font-medium text-center text-pcdwhite transition duration-500 ease-in-out transform bg-pcdblue rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg hover:shadow-2xl hover:-translate-y-1">
                <a href="mailto:hello@paradecode.com">hello@paradecode.com</a>
              </button>
            </div>
            <h3 className="text-xl font-semibold mt-10 text-pcdblue">
              Socials
            </h3>
            <div className="social-wrapper flex flex-row pt-5">
              <a
                href="https://www.instagram.com/paradecodedigital/"
                className="text-pcdwhite hover:text-pcdblue mr-5"
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  className="h-6 w-6 text-white hover:text-blue-700"
                  viewBox="0 0 24 24"
                  fillRule="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />{" "}
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                </svg>
                <span className="sr-only">Instagram page</span>
              </a>
              <a
                href="https://www.facebook.com/ParadeCode-Digital-107272448714884"
                className="text-pcdwhite hover:text-pcdblue mr-5"
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  className="h-6 w-6 text-white hover:text-blue-700"
                  viewBox="0 0 24 24"
                  fillRule="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                </svg>
                <span className="sr-only">Facebook page</span>
              </a>
              <a
                href="https://www.linkedin.com/in/paradecodedigital/"
                className="mr-5 text-pcdwhite hover:text-pcdblue"
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  className="h-6 w-6 text-white hover:text-blue-700"
                  viewBox="0 0 24 24"
                  fillRule="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />{" "}
                  <rect x="2" y="9" width="4" height="12" />{" "}
                  <circle cx="4" cy="4" r="2" />
                </svg>
                <span className="sr-only">Linkedin page</span>
              </a>
            </div>
            <h3 className="text-3xl whitespace-nowrap md:whitespace-normal  md:text-7xl font-extrabold text-pcdwhite mt-10">
              Open for business
            </h3>
            <p className="text-pcdoffwhite text-sm font-medium  mt-5">
              Share your thoughts, ideas, and goals, and we will explore
              strategies, campaigns, and build a a big impact experiences making
              clients loyal to your brand.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
