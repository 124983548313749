import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";

const animation = gsap.timeline({ paused: true, reversed: true });

const Navbar = () => {
  const MenuLinks = [
    {
      name: "About",
      link: "/#about",
      image: require("./../../assets/img/icons/paradecode-digital.webp"),
      alt: "ParadeCode Digital About Company",
      tag: "Who we are.",
    },
    {
      name: "Services",
      link: "/#services",
      image: require("./../../assets/img/navbar/web-development-paradecode-digital.gif"),
      alt: "ParadeCode Digital Company Services",
      tag: "What we do.",
    },
    {
      name: "Showcase",
      link: "/#showcase",
      image: require("./../../assets/img/navbar/portfolio-paradecode-digital.gif"),
      alt: "ParadeCode Digital Company showcase",
      tag: "What we done.",
    },
    {
      name: "Contact",
      link: "/#contact",
      image: require("./../../assets/img/navbar/contact-paradecode-digital.gif"),
      alt: "Contact ParadeCode Digital",
      tag: "Where we are.",
    },
  ];

  const navRef = useRef();
  const menuRef = useRef();

  useEffect(() => {
    const topbar = menuRef.current.querySelector(".top-bar");
    const bottombar = menuRef.current.querySelector(".bottom-bar");

    animation
      .to(topbar, { rotate: "45deg", y: "5px", duration: "0.15" })
      .to(bottombar, {
        rotate: "-45deg",
        y: "-5px",
        duration: "0.15",
        delay: "-0.15",
      })
      .to(navRef.current, {
        autoAlpha: 1,
        ease: "sine.easeInOut",
        duration: "0.15",
      });
  });

  const handleMenuClick = () => {
    animation.reversed() ? animation.play() : animation.reverse();
  };
  const handleNavLinkClick = () => {
    animation.reverse();
  };

  return (
    <nav className="w-screen py-3 px-5 fixed border-b top z-50 bg-pcdwhite text-pcdblack">
      <div className="navbar-wrapper flex flex-row justify-between items-center">
        <HashLink to="/">
          <span className=" text-lg font-bold whitespace-nowrap bg-pcdwhite text-pcdblack tracking-tighter">
            &#9679;<span className="text-pcdblue pr-1">&#9679;</span>
            ParadeCode Digital
          </span>
        </HashLink>

        <ul className="lg:flex items-center mr-10 hidden">
          <HashLink smooth to="/#about">
            <li className="font-heading mr-9 text-gray-900 hover:bg-pcdoffwhite hover:text-pcdblue hover:underline text-sm font-medium py-2 px-3 rounded-2xl transition ease-in-out duration-300">
              {/* <a href="/#about">About</a> */}About
            </li>
          </HashLink>
          <HashLink smooth to="/#services">
            <li className="font-heading mr-9 text-gray-900 hover:bg-pcdoffwhite hover:text-pcdblue hover:underline text-sm font-medium py-2 px-3 rounded-2xl transition ease-in-out duration-300">
              {/* <a href="/#services">Services</a> */}Services
            </li>
          </HashLink>
          <HashLink smooth to="/#showcase">
            <li className="font-heading mr-9 text-gray-900 hover:bg-pcdoffwhite hover:text-pcdblue hover:underline text-sm font-medium py-2 px-3 rounded-2xl transition ease-in-out duration-300">
              {/* <a href="/#showcase">Showcase</a> */}Showcase
            </li>
          </HashLink>
          <HashLink smooth to="/#contact">
            <li className="font-heading bg-pcdblue text-pcdwhite hover:bg-pcdoffwhite hover:text-pcdblue  hover:underline text-sm font-medium py-2 px-3 rounded-2xl transition ease-in-out duration-300">
              {/* <a href="/#contact">Contact</a> */}Contact
            </li>
          </HashLink>
        </ul>
        <button
          className="collapsed navbar-toggler w-[30px] h-[30px] rounded-full bg-pcdoffwhite hover:ring-2 hover:ring-pcdblue lg:hidden"
          type="button"
          data-toggle="collapse"
          data-target="#navbarID"
          aria-controls="navbarID"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="togglerbtn"
          ref={menuRef}
          onClick={handleMenuClick}
        >
          <div
            className="div toggle-btn flex flex-col mx-auto justify-evenly w-full h-full"
            id="togglebtn"
          >
            <div className="top-bar toggler-icon bg-pcdblack flex w-[20px] h-[2px] mx-auto"></div>
            <div className="bottom-bar toggler-icon bg-pcdblack flex w-[20px] h-[2px] mx-auto"></div>
          </div>
          <div
            className="mobile-menu z-40 absolute w-76 rounded-2xl invisible top-18 right-10"
            ref={navRef}
            onClick={handleNavLinkClick}
          >
            <ul className=" p-2 bg-pcdwhite border border-gray-200 rounded-2xl shadow-2xl z-50">
              {MenuLinks.map((links, link) => (
                <li className="rounded-xl hover:bg-pcdoffwhite" key={link}>
                  <HashLink to={links.link}>
                    <div className="">
                      <div className="flex items-center space-x-10 p-4">
                        <img
                          src={links.image}
                          alt={links.alt}
                          className="object-cover w-[80px] h-[80px] rounded-lg"
                        />
                        <div className="ml-3 overflow-hidden text-left p-3 border-l border-gray-300">
                          <p className="font-semibold text-gray-900 tracking-tighter">
                            {links.name}
                          </p>
                          <p className="max-w-sm text-sm text-gray-400 tracking-tighter">
                            {links.tag}
                          </p>
                        </div>
                      </div>
                    </div>
                  </HashLink>
                </li>
              ))}
            </ul>
          </div>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
