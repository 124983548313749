import React from "react";
import Hero from "./components/Sections/Hero";
import CTA from "./components/Sections/CTA";
import HeroVideo from "./components/Sections/HeroVideo";
import About from "./components/Sections/About";
import Services from "./components/Sections/Services";
import Portfolio from "./components/Sections/Portfolio";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      delay: 0.25,
    });

    var HasPlayed = sessionStorage.getItem("hasPlayed");

    tl.fromTo(
      ".text-one",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.75, ease: "power1" }
    );
    tl.fromTo(
      ".text-two",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.75, ease: "power1" }
    );
    tl.fromTo(
      ".span1",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.75, ease: "power1" }
    );
    tl.fromTo(
      ".span2",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.75, ease: "power1" }
    );
    tl.fromTo(
      ".span3",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.75, ease: "power1" }
    );
    // tl.fromTo(
    //   ".span4",
    //   { y: 50, opacity: 0 },
    //   { y: 0, opacity: 1, duration: 0.75, ease: "power1" }
    // );
    // tl.fromTo(
    //   ".span3",
    //   { y: 50, opacity: 0 },
    //   { y: 0, opacity: 1, duration: 0.75, ease: "power1" }
    // );
    tl.fromTo(
      ".ctaone",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.5, ease: "power1" }
    );
    tl.fromTo(
      ".ctatwo",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.5, ease: "power3" }
    );
    tl.fromTo(
      ".hero-video-section",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.5, ease: "none" }
    );
    tl.fromTo(
      ".about",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.5, ease: "none" }
    );
    if (!HasPlayed) {
      sessionStorage.setItem("hasPlayed", true);
    }
  }, []);

  return (
    <div>
      <Hero />
      <CTA />
      <HeroVideo />
      <About />
      <Services />
      <Portfolio />
    </div>
  );
};

export default Home;
