import React from "react";

const Hero = (props) => {
  return (
    // <section className="hero w-full py-5 px-5 bg-pcdwhite text-pcdblack">
    //   <div className="hero-wrapper mx-auto flex flex-col text-left container font-semibold pt-60 pb-12">
    //     <p className="text-2xl sm:text-4xl md:text-6xl xl:text-7xl text-one tracking-tighter ">
    //       Building Digital Experiences
    //     </p>
    //     <p className="text-2xl sm:text-4xl md:text-6xl xl:text-7xl text-two tracking-tighter">
    //       Combining Analytics with Creative Strategy.
    //     </p>
    // <div
    //   className="py-1 text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl bg-clip-text bg-gradient-to-r text-pcdblue font-bold
    //  text-three tracking-tighter"
    // >
    //   <span className="span1">Branding.</span>
    //   <span className="span2">Websites.</span>
    //   <span className="span3">SEO.</span>
    //   <span className="span4">Social.</span>
    // </div>
    //   </div>
    // </section>
    <section className="hero w-screen py-5 px-5 bg-pcdwhite text-pcdblack">
      <div className="hero-wrapper flex flex-col container mx-auto font-semibold pt-36 pb-12">
        <h1 className="text-2xl md:text-6xl xl:text-7xl text-one tracking-tighter text-left lg:text-right">
          Creating Digital Experiences
        </h1>
        <h1 className="text-2xl md:text-6xl xl:text-7xl text-two tracking-tighter text-left lg:text-center">
          Fusing Data with Creative Strategy.
        </h1>
        <div
          className="py-1 text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl bg-clip-text bg-gradient-to-r text-pcdblue font-bold
         text-three tracking-tighter"
        >
          {/* <span className="span3">Branding.</span> */}
          <span className="span1">Search Engine Optimization.</span>
          <br></br>
          <span className="span2 lg:pl-36">Web Design</span>
          <br></br>
          <span className="span3 lg:pl-72">3D Renders.</span>
          {/* <span className="span4">Social.</span> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
